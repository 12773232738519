import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import sessionService from '../services/sessionService';
import teamService from '../services/teamService';
import authService from '../services/authService';
import Header from '../components/ui/Header';
import clannLogo from '../assets/images/clann.ai-green.png';
import playerIcon from '../assets/images/icon-player.png';
import MatchesSection from '../components/MatchesSection';
import TeamPage from '../components/TeamPage';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function UserDashboard() {
  const [activeTab, setActiveTab] = useState('matches');
  const [user, setUser] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [url, setUrl] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamCode, setTeamCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [teamColor, setTeamColor] = useState('#D1FB7A'); // Clann light green default
  const [teamStatus, setTeamStatus] = useState({ isPremium: false, status: 'FREE' });
  const [teamDetails, setTeamDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (!userData) {
      navigate('/');
      return;
    }
    setUser(userData);
    
    const initializeData = async () => {
      await fetchTeamStatus();
      await fetchTeamDetails();
      await fetchSessions();
      if (activeTab === 'team') {
        await fetchTeamMembers();
      }
    };

    initializeData();
  }, [navigate, activeTab]);

  const fetchSessions = async () => {
    try {
      const data = await sessionService.getSessions();
      // Fetch detailed session data for each session
      const sessionsWithData = await Promise.all(
        data.map(async (session) => {
          const details = await sessionService.getSessionDetails(session.id);
          return {
            ...session,
            session_data: details.session_data
          };
        })
      );
      setSessions(sessionsWithData);
    } catch (err) {
      console.error('Failed to fetch sessions:', err);
    }
  };

  const fetchTeamMembers = async () => {
    try {
      if (!user?.team?.id) {
        setTeamMembers([]);
        return;
      }
      const data = await teamService.getTeamMembers(user.team.id);
      if (!data) {
        throw new Error('No team data received');
      }
      setTeamMembers(data);
    } catch (err) {
      console.error('Team members fetch error:', err);
      setFeedback({
        type: 'error',
        message: 'Unable to load team members. Please try again.'
      });
      setTeamMembers([]);
    }
  };

  const fetchTeamStatus = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${user.teamId}`);
      const data = await response.json();
      setTeamStatus({
        isPremium: data.is_premium,
        status: data.subscription_status
      });
    } catch (err) {
      console.error('Error fetching team status:', err);
    }
  };

  const fetchTeamDetails = async () => {
    try {
      if (!user?.teamId) {
        console.log('No team ID available');
        setTeamDetails(null);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${user.teamId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch team details');
      }
      
      const data = await response.json();
      console.log('Team details fetched:', data);
      setTeamDetails(data);
      // Also update team name
      setTeamName(data.name);
    } catch (err) {
      console.error('Error fetching team details:', err);
      setTeamDetails(null);
    }
  };

  const handleRemoveMember = async (teamId, userId) => {
    if (window.confirm('Are you sure you want to remove this member?')) {
      try {
        await teamService.removeTeamMember(teamId, userId);
        fetchTeamMembers();
        setFeedback({
          type: 'success',
          message: 'Team member removed successfully'
        });
      } catch (err) {
        console.error('Remove member error:', err);
        setFeedback({
          type: 'error',
          message: 'Failed to remove team member'
        });
      }
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFeedback(null);

    if (!url.trim() || !teamName.trim()) {
      setFeedback({
        type: 'error',
        message: 'Please fill in all fields'
      });
      setIsLoading(false);
      return;
    }

    try {
      // Store upload data in localStorage for after payment
      localStorage.setItem('pendingUpload', JSON.stringify({
        url: url.trim(),
        teamName: teamName.trim(),
        teamColor
      }));

      // Get Stripe instance
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Failed to initialize Stripe');
      }

      // Create checkout session
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          teamId: user.teamId,
          trialPeriod: true,
          price: 7500
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        if (error.error === 'Team already has an active subscription') {
          setFeedback({
            type: 'error',
            message: 'Your team already has an active subscription'
          });
          return;
        }
        throw new Error('Failed to create checkout session');
      }

      const { id: sessionId } = await response.json();
      
      // Redirect to checkout
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        throw error;
      }

    } catch (err) {
      console.error('Upload/payment error:', err);
      setFeedback({
        type: 'error',
        message: err.message || 'Failed to process payment'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoinTeam = async (e) => {
    e.preventDefault();
    if (!teamCode.trim()) {
      setFeedback({
        type: 'error',
        message: 'Please enter a team code'
      });
      return;
    }

    try {
      setIsLoading(true);
      setFeedback(null);

      // Call the join team endpoint
      const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/join`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ teamCode: teamCode.trim() })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to join team');
      }

      const data = await response.json();
      
      // Refresh user data and sessions
      await fetchSessions();
      const userData = await teamService.getCurrentUser();
      setUser(userData);
      
      setFeedback({
        type: 'success',
        message: `Successfully joined ${data.team.name}`
      });
      
      setTeamCode('');
      setShowJoinModal(false);
      
    } catch (err) {
      console.error('Join team error:', err);
      setFeedback({
        type: 'error',
        message: err.message || 'Failed to join team. Please check your team code.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/');
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        await authService.deleteAccount();
        localStorage.removeItem('user');
        navigate('/');
      } catch (err) {
        setFeedback({
          type: 'error',
          message: 'Failed to delete account'
        });
      }
    }
  };

  const generateInviteMessage = (teamCode, teamName) => {
    return `🎮 Join "${teamName}" on Clann AI!\n\n` +
      `To join:\n` +
      `1. Go to https://clannai.com\n` +
      `2. Create an account\n` +
      `3. Click "Join Team"\n` +
      `4. Enter team code: ${teamCode}`;
  };

  const copyInviteMessage = (teamCode, teamName) => {
    const message = generateInviteMessage(teamCode, teamName);
    navigator.clipboard.writeText(message);
    setFeedback({
      type: 'success',
      message: 'Invite message copied to clipboard!'
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result);
        // Here you would typically upload to your server
        // For now, we'll just store in localStorage
        localStorage.setItem('userProfileImage', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadModalOpen = () => {
    // Pre-fill team name from most recent session if it exists
    if (sessions.length > 0) {
      setTeamName(sessions[0].team_name);
    }
    setShowUploadModal(true);
  };

  // Add color options
  const colorOptions = [
    { name: 'Clann Green', value: '#D1FB7A' },
    { name: 'Clann Blue', value: '#B9E8EB' },
    { name: 'Red', value: '#FF6B6B' },
    { name: 'Orange', value: '#FFB067' },
    { name: 'Purple', value: '#B197FC' },
    { name: 'Yellow', value: '#FFE066' },
  ];

  const handleColorChange = (color) => {
    setTeamColor(color);
    // Implement the logic to update the team color in the sessions
  };

  const handleUpgrade = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Failed to initialize Stripe');

      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          teamId: user.teamId,
          trialPeriod: true,
          price: 7500
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create checkout session');
      }

      const { id: sessionId } = await response.json();
      const { error } = await stripe.redirectToCheckout({ sessionId });
      
      if (error) throw error;

    } catch (err) {
      console.error('Upgrade error:', err);
      setFeedback({
        type: 'error',
        message: err.message || 'Failed to process upgrade'
      });
    }
  };

  return (
    <div className="min-h-screen bg-[#F7F6F1]">
      {/* Top Navigation */}
      <nav className="border-b border-gray-200/10">
        <div className="max-w-7xl mx-auto px-8 py-6">
          <div className="flex items-center justify-between">
            <img src={clannLogo} alt="Clann" className="h-7" />
            
            <div className="flex items-center gap-8">
              <button 
                onClick={handleUploadModalOpen}
                className="bg-[#016F32] text-white px-6 py-2.5 rounded-lg font-medium"
              >
                Upload a match
              </button>
              <button 
                onClick={() => setShowJoinModal(true)}
                className="bg-[#B9E8EB] text-gray-900 px-6 py-2.5 rounded-lg font-medium"
              >
                Join team
              </button>
              <button 
                onClick={() => setShowSettingsModal(true)} 
                className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281Z" />
                </svg>
                Settings
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* User Profile Section */}
      <div className="max-w-7xl mx-auto px-8 py-12">
        <div className="flex items-center gap-6 mb-12">
          <div className="flex items-center gap-8">
            <div>
              <h1 className="text-3xl font-bold mb-2">
                {sessions.find(s => s.team_name !== 'ClannAI demo')?.team_name || 'My Team'}
                <span className={`ml-2 px-2 py-1 text-sm rounded-full ${
                  teamStatus.isPremium 
                    ? 'bg-green-400/10 text-green-400' 
                    : 'bg-gray-400/10 text-gray-400 hover:bg-green-400/10 hover:text-green-400 cursor-pointer'
                }`}
                onClick={!teamStatus.isPremium ? handleUpgrade : undefined}>
                  {teamStatus.isPremium ? '⭐️ PREMIUM' : 'FREE TIER - Upgrade'}
                </span>
              </h1>
              <div className="flex items-center gap-2 text-gray-600">
                <span>⚽</span>
                <div>
                  <p className="text-sm text-gray-500">Team Member:</p>
                  <p className="text-lg">{user?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Page Tabs */}
        <div className="border-b border-gray-200 mb-8">
          <div className="flex gap-12">
            <button 
              onClick={() => setActiveTab('matches')}
              className={`pb-4 text-lg font-medium ${activeTab === 'matches' 
                ? 'text-gray-900 border-b-2 border-[#016F32]' 
                : 'text-gray-500'}`}
            >
              Your Matches
            </button>
            <button 
              onClick={() => setActiveTab('team')}
              className={`pb-4 text-lg font-medium ${activeTab === 'team' 
                ? 'text-gray-900 border-b-2 border-[#016F32]' 
                : 'text-gray-500'}`}
            >
              Team Page
            </button>
          </div>
        </div>

        {/* Content Area */}
        <div className="space-y-4">
          {activeTab === 'matches' ? (
            <MatchesSection sessions={sessions} />
          ) : (
            <TeamPage 
              sessions={sessions}
              teamMembers={teamMembers}
              onUploadClick={() => setShowUploadModal(true)}
              onJoinClick={() => setShowJoinModal(true)}
              generateInviteMessage={generateInviteMessage}
              copyInviteMessage={copyInviteMessage}
            />
          )}
        </div>
      </div>
      {/* Feedback Toast */}
      {feedback && (
        <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
          feedback.type === 'error' 
            ? 'bg-red-50 text-red-600'
            : 'bg-green-50 text-green-600'
        }`}>
          {feedback.message}
        </div>
      )}
      {/* Upload Modal */}
      {showUploadModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Upload Match</h2>
            <form onSubmit={handleUpload} className="space-y-4">
              <div>
                <label className="block text-sm text-gray-600 mb-2">Match URL</label>
                <input
                  type="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter match URL"
                  className="w-full px-4 py-2 rounded-lg border"
                  required
                />
              </div>
              <div>
                <label className="block text-sm text-gray-600 mb-2">Team Name</label>
                <input
                  type="text"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  placeholder="Enter team name"
                  className="w-full px-4 py-2 rounded-lg border"
                  required
                />
              </div>
              <div>
                <label className="block text-sm text-gray-600 mb-2">Team Color</label>
                <div className="flex gap-2 flex-wrap">
                  {colorOptions.map((color) => (
                    <button
                      key={color.value}
                      type="button"
                      onClick={() => setTeamColor(color.value)}
                      className={`w-8 h-8 rounded-full border-2 transition-all ${
                        teamColor === color.value 
                          ? 'border-gray-900 scale-110' 
                          : 'border-transparent hover:scale-105'
                      }`}
                      style={{ backgroundColor: color.value }}
                      title={color.name}
                    />
                  ))}
                </div>
              </div>
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={() => {
                    setShowUploadModal(false);
                    setTeamColor('#D1FB7A'); // Reset to default when closing
                  }}
                  className="px-4 py-2 text-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#016F32] text-white px-6 py-2 rounded-lg font-medium disabled:opacity-50"
                >
                  {isLoading ? 'Uploading...' : 'Upload Match'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Join Team Modal */}
      {showJoinModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Join Team</h2>
            <form onSubmit={handleJoinTeam} className="space-y-4">
              <div>
                <label className="block text-sm text-gray-600 mb-2">Team Code</label>
                <input
                  type="text"
                  value={teamCode}
                  onChange={(e) => setTeamCode(e.target.value)}
                  placeholder="Enter team code"
                  className="w-full px-4 py-2 rounded-lg border"
                  required
                />
              </div>
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={() => setShowJoinModal(false)}
                  className="px-4 py-2 text-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-[#016F32] text-white px-6 py-2 rounded-lg font-medium disabled:opacity-50"
                >
                  {isLoading ? 'Joining...' : 'Join Team'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Settings Modal */}
      {showSettingsModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-900">Settings</h2>
              <button
                onClick={() => setShowSettingsModal(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
            </div>

            {/* Add email display */}
            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-500">Account Email</p>
              <p className="text-gray-900 font-medium">{user?.email}</p>
            </div>

            {/* Subscription Section */}
            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
              <div className="flex justify-between items-center mb-2">
                <p className="text-gray-900 font-medium">Current Plan</p>
                <span className={`px-2 py-1 rounded-full text-xs ${
                  teamStatus.isPremium ? 'bg-green-400/10 text-green-400' : 'bg-gray-400/10 text-gray-400'
                }`}>
                  {teamStatus.isPremium ? '⭐️ PREMIUM' : 'FREE TIER'}
                </span>
              </div>
              
              {teamStatus.isPremium ? (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Manage your subscription, update payment method, or view billing history
                  </p>
                  <button
                    onClick={async () => {
                      try {
                        const response = await fetch(
                          `${process.env.REACT_APP_API_URL}/teams/${user.teamId}/billing-portal`,
                          { method: 'POST' }
                        );
                        if (!response.ok) throw new Error('Failed to access billing portal');
                        const { url } = await response.json();
                        window.open(url, '_blank');
                      } catch (err) {
                        console.error('Billing portal error:', err);
                        setFeedback({
                          type: 'error',
                          message: 'Failed to open billing portal'
                        });
                      }
                    }}
                    className="w-full px-4 py-2 bg-gray-100 text-gray-700 rounded-lg 
                             hover:bg-gray-200 transition-colors flex items-center justify-center gap-2"
                  >
                    <span>Manage Subscription</span>
                    <span className="text-xs">↗</span>
                  </button>
                </>
              ) : (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Upgrade to Premium for unlimited matches and advanced analytics
                  </p>
                  <button
                    onClick={handleUpgrade}
                    className="w-full px-4 py-2 bg-green-50 text-green-700 rounded-lg 
                             hover:bg-green-100 transition-colors"
                  >
                    Upgrade to Premium
                  </button>
                </>
              )}
            </div>

            <div className="space-y-4">
              <button
                onClick={handleLogout}
                className="w-full text-left px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
              >
                Sign Out
              </button>
              <button
                onClick={handleDeleteAccount}
                className="w-full text-left px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserDashboard; 