import React from 'react';
import { useNavigate } from 'react-router-dom';
import SpiderChart from './SpiderChart';

function MatchesSection({ sessions }) {
  const navigate = useNavigate();

  return (
    <>
      <h2 className="text-2xl font-bold mb-6">Your Matches</h2>
      {sessions.map(session => {
        const team1 = session.session_data?.match_info?.team1;
        const team2 = session.session_data?.match_info?.team2;
        const score = session.session_data?.match_info?.score;

        return (
          <div
            key={session.id}
            onClick={() => navigate(`/session/${session.id}`)}
            className="bg-gray-800/50 rounded-xl p-6 border border-gray-700/50 hover:border-green-500/30 transition-all cursor-pointer mb-6"
          >
            {/* Match Header */}
            <div className="flex justify-between items-center mb-6 border-b border-gray-700/50 pb-4">
              <div>
                <h3 className="font-bold text-xl text-[#D1FB7A]">{team1?.name || session.team_name}</h3>
                <p className="text-gray-400 text-sm mt-1">{new Date(session.created_at).toLocaleDateString()}</p>
              </div>
              
              {/* Score Display */}
              <div className="flex items-center gap-4">
                <span className="text-2xl font-bold text-[#D1FB7A]">{score?.team1 || '-'}</span>
                <span className="text-gray-400">vs</span>
                <span className="text-2xl font-bold text-[#B9E8EB]">{score?.team2 || '-'}</span>
              </div>
              
              <div className="text-right">
                <h3 className="font-bold text-xl text-[#B9E8EB]">{team2?.name || 'Opponent'}</h3>
              </div>
            </div>

            {/* Stats Grid */}
            <div className="grid grid-cols-5 gap-8">
              <div className="text-center">
                <p className="text-sm text-gray-400 mb-1">Distance</p>
                <div className="flex justify-center gap-3">
                  <p className="font-bold text-[#D1FB7A]">{team1?.metrics?.total_distance?.toFixed(1) || '-'}</p>
                  <p className="text-gray-400">|</p>
                  <p className="font-bold text-[#B9E8EB]">{team2?.metrics?.total_distance?.toFixed(1) || '-'}</p>
                  <span className="text-gray-400 text-sm">km</span>
                </div>
              </div>

              <div className="text-center">
                <p className="text-sm text-gray-400 mb-1">Energy</p>
                <div className="flex justify-center gap-3">
                  <p className="font-bold text-[#D1FB7A]">{team1?.metrics?.energy?.toFixed(1) || '-'}</p>
                  <p className="text-gray-400">|</p>
                  <p className="font-bold text-[#B9E8EB]">{team2?.metrics?.energy?.toFixed(1) || '-'}</p>
                  <span className="text-gray-400 text-sm">kJ</span>
                </div>
              </div>

              <div className="text-center">
                <p className="text-sm text-gray-400 mb-1">Sprints</p>
                <div className="flex justify-center gap-3">
                  <p className="font-bold text-[#D1FB7A]">{team1?.metrics?.total_sprints || '-'}</p>
                  <p className="text-gray-400">|</p>
                  <p className="font-bold text-[#B9E8EB]">{team2?.metrics?.total_sprints || '-'}</p>
                </div>
              </div>

              <div className="text-center">
                <p className="text-sm text-gray-400 mb-1">Sprint Dist</p>
                <div className="flex justify-center gap-3">
                  <p className="font-bold text-[#D1FB7A]">{team1?.metrics?.sprint_distance || '-'}</p>
                  <p className="text-gray-400">|</p>
                  <p className="font-bold text-[#B9E8EB]">{team2?.metrics?.sprint_distance || '-'}</p>
                  <span className="text-gray-400 text-sm">m</span>
                </div>
              </div>

              <div className="text-center">
                <p className="text-sm text-gray-400 mb-1">Sprint Speed</p>
                <div className="flex justify-center gap-3">
                  <p className="font-bold text-[#D1FB7A]">{team1?.metrics?.avg_sprint_speed?.toFixed(1) || '-'}</p>
                  <p className="text-gray-400">|</p>
                  <p className="font-bold text-[#B9E8EB]">{team2?.metrics?.avg_sprint_speed?.toFixed(1) || '-'}</p>
                  <span className="text-gray-400 text-sm">m/s</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default MatchesSection;
