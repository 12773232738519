import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import teamService from '../services/teamService';
import sessionService from '../services/sessionService';

function Success() {
    const navigate = useNavigate();
    const [status, setStatus] = useState('processing');

    useEffect(() => {
        // Short delay to allow webhook to process
        setTimeout(() => {
            setStatus('completed');
            // Redirect to profile after 2 seconds
            setTimeout(() => {
                navigate('/profile');
            }, 2000);
        }, 3000);
    }, [navigate]);

    return (
        <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-center">
            <h1 className="text-3xl font-bold mb-4">Payment Successful!</h1>
            <p className="text-gray-400 mb-8">Your team is now premium with a 7-day free trial</p>
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
            <p className="mt-4 text-gray-400">Redirecting to your profile...</p>
        </div>
    );
}

export default Success; 